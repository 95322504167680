<template>
  <div style="position: relative">
    <div class="edit" v-if="questionId">
      <div class="form" :style="{ height: cardH - 22 + 'px' }">
        <Form :label-width="90">
          <FormItem label="题目类型" prop="questionType">
            <RadioGroup type="button" v-model="questionForm.questionType">
              <Radio disabled :label="item.value" v-for="(item, index) in questionType" :key="index">{{ item.label }}</Radio>
            </RadioGroup>
          </FormItem>

          <FormItem label="题干">
            <div
              v-if="!editStem"
              @click="editStem = true"
              style="display: flex;align-items: center;cursor: pointer;"
              @mouseenter="stemHover = true"
              @mouseleave="stemHover = false"
            >
              <div v-html="questionForm.stem"></div>
              <img v-if="stemHover" src="../../../../assets/组602@2x.png" width="14" height="16" style="margin-left: 8px" />
            </div>
            <div v-else style="position: relative">
              <RichEditor v-model="questionForm.stem" />
              <div class="option-action">
                <div class="hide" @click="closeStem">收起</div>
              </div>
              <span v-if="questionForm.questionType === 3">空格处使用两个下划线和一个序号类似'__1__'的格式标注</span>
            </div>
          </FormItem>
          <FormItem label="选项" v-if="questionForm.questionType === 1 || questionForm.questionType === 2">
            <div
              @mouseenter="opIndex = index"
              @mouseleave="opIndex = ''"
              v-for="(op, index) in questionForm.options"
              :key="op.content + index"
              style="display: flex;align-items: center"
            >
              <div v-if="showEnd(index)" style="width: 100%">
                <div @click="editOP = index" v-if="editOP !== index" style="display: flex;align-items: center;cursor: pointer">
                  <span
                    :style="{ color: op.correct ? '#5ABC50' : '#1F1F1F' }"
                    v-html="String.fromCharCode(index + 65) + '.' + op.content.replace(/\<\/?p(?!re)([^>]+)?\>/g, ' ')"
                  ></span>
                  <img v-if="opIndex === index" src="../../../../assets/组602@2x.png" width="14" height="16" style="margin-left: 8px" />
                </div>
                <div v-else style="display: flex;width: 100%;position: relative;margin-bottom: 8px">
                  <div style="margin-right: 4px">
                    {{ String.fromCharCode(index + 65) }}
                  </div>
                  <div style="flex: auto">
                    <RichEditor v-model="op.content"></RichEditor>
                    <div class="option-action">
                      <Checkbox v-model="op.correct" @on-change="val => setCorrect(val, index)" style="color: #5ABC50">设为正解</Checkbox>
                      <Button
                        @click="
                          editOP = ''
                          questionForm.options.splice(index, 1)
                        "
                        shape="circle"
                        size="small"
                        style="margin: 0 10px"
                        >删除</Button
                      >
                      <div class="hide" @click="close(index, 'edit')">收起</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Button
              @click="
                addOP = true
                questionForm.options.push({
                  content: '',
                  score: null,
                  correct: false
                })
              "
              v-if="!addOP"
              type="primary"
              ghost
              icon="ios-add-circle-outline"
              size="small"
              style="display: flex"
              >添加选项</Button
            >
            <div v-else style="position: relative;display: flex;width: 100%">
              <div style="margin-right: 4px">
                {{ String.fromCharCode(questionForm.options.length - 1 + 65) }}
              </div>
              <RichEditor style="flex: auto" v-model="questionForm.options[questionForm.options.length - 1].content" />
              <div class="option-action">
                <Checkbox
                  @on-change="val => setCorrect(val, questionForm.options.length - 1)"
                  v-model="questionForm.options[questionForm.options.length - 1].correct"
                  style="color: #5ABC50"
                  >设为正解</Checkbox
                >
                <Button
                  @click="
                    addOP = false
                    questionForm.options.pop()
                  "
                  shape="circle"
                  size="small"
                  style="margin: 0 10px"
                  >删除</Button
                >
                <div class="hide" @click="close(questionForm.options.length - 1, 'add')">
                  收起
                </div>
              </div>
            </div>
          </FormItem>
          <FormItem label="填空" v-if="questionForm.questionType === 3">
            <Input
              :ref="'input' + index"
              @on-blur="confirmBlank(index)"
              v-for="(b, index) in questionForm.blanks"
              v-model="b.blankAnswer"
              :key="index"
              style="margin-bottom: 4px"
            >
              <Button @click="delBlank(index)" slot="append" icon="md-close" />
            </Input>
            <Button @click="addBlank" type="primary" ghost icon="ios-add-circle-outline" size="small" style="display: flex">添加填空</Button>
          </FormItem>
          <FormItem label="答案" v-if="questionForm.questionType === 0">
            <RadioGroup v-model="questionForm.answer">
              <Radio label="true">正确</Radio>
              <Radio label="false">错误</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="代码模板" v-show="questionForm.questionType === 5">
            <JYCodeEditor ref="code-editor" style="border: solid 1px #e3e3e3" v-model="questionForm.template" />
          </FormItem>
          <FormItem label="解析">
            <div
              v-if="!editAnalysis"
              @click="editAnalysis = true"
              style="cursor: pointer;display: flex;align-items: center"
              @mouseenter="analysisHover = true"
              @mouseleave="analysisHover = false"
            >
              <div v-html="questionForm.analysis"></div>
              <img
                v-if="analysisHover || !questionForm.analysis"
                src="../../../../assets/组602@2x.png"
                width="14"
                height="16"
                :style="{
                  margin: questionForm.analysis ? '0 0 0 8px' : '8px 0 0 8px'
                }"
              />
            </div>
            <div v-else style="position: relative">
              <RichEditor v-model="questionForm.analysis"></RichEditor>
              <div class="option-action">
                <div class="hide" @click="editAnalysis = false">收起</div>
              </div>
            </div>
          </FormItem>
          <FormItem label="知识点">
            <div class="kInput" @click="showDraw">
              <div style="flex: auto">
                <Tag
                  style="margin: 0 8px 0 0"
                  @on-close="closeTag(index)"
                  color="#E7EFF7"
                  v-for="(item, index) in knowledgePoints"
                  :key="index"
                  closable
                  >{{ item.knowledgeName }}</Tag
                >
              </div>
              <Button
                @click="clearTag"
                v-if="knowledgePoints ? (knowledgePoints.length === 0 ? false : true) : false"
                shape="circle"
                style="width:56px;height: 22px;line-height: 22px;color: #5E6470;font-size: 12px"
                >清空</Button
              >
            </div>
            <!--          <el-cascader style="width: 100%" ref="cascader" v-model="questionForm.knowledgePoints" :props="treeProps" filterable label="name" :options="knowledgePointTree" clearable></el-cascader>-->
          </FormItem>
          <FormItem label="难易程度">
            <RadioGroup type="button" v-model="questionForm.difficulty">
              <Radio :label="item.value" v-for="(item, index) in difficulty" :key="index">{{ item.label }}</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="预计完成时间">
            <InputNumber :min="1" v-model="questionForm.expectedTime"></InputNumber><span style="margin-left: 8px">分钟</span>
          </FormItem>
          <FormItem label="标签">
            <Select
              :class="tags.length === 0 ? 'tag' : ''"
              not-found-text="暂无数据"
              placement="top-start"
              v-model="questionForm.tags"
              filterable
              allow-create
              multiple
              @on-create="handleCreate"
              placeholder="请选择或输入标签"
              style="width: calc(100% - 230px);"
            >
              <Option v-for="(item, index) in tags" :value="item.name" :key="item.name + index">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="审核" prop="status">
            <i-switch v-model="isChecked" />
          </FormItem>
        </Form>
      </div>
      <div class="foot">
        <div v-if="isShow" class="cancel" @click="cancelEdit">取消</div>
        <Button @click="release(1)" :loading="saveLoading" :disabled="isChecked">保存草稿</Button>
        <Button style="margin: 0 20px" @click="del" :loading="delLoading">删除</Button>
        <Button @click="release(isChecked ? 0 : 2)" type="primary" :loading="releaseLoading">发布</Button>
      </div>
    </div>
    <Spin size="large" fix v-if="spinShow" />
    <Drawer
      title="知识点"
      v-model="showDrawer"
      :mask="false"
      @on-close="
        showDrawer = false
        filterText = ''
      "
    >
      <Input ref="kInput" placeholder="请输入关键字" v-model="filterText" style="margin: 0 0 8px 8px;width: 220px"></Input>
      <div :style="{ height: cardH - 80 + 'px', overflow: 'auto' }">
        <el-tree
          :filter-node-method="filterNode"
          node-key="id"
          ref="modalTree"
          @check-change="handleCheckChange"
          :data="knowledgePointTree"
          :props="{ label: 'name' }"
          check-strictly
          show-checkbox
        ></el-tree>
      </div>
    </Drawer>
  </div>
</template>

<script>
import questionApi from '@api/questionRepo'
import RichEditor from '@components/common/RichEditor'
import { mapState } from 'vuex'
import _ from 'underscore'
import JYCodeEditor from '../../../common/JYCodeEditor'

export default {
  name: 'QuestionEdit',
  components: { RichEditor, JYCodeEditor },
  props: ['questionId', 'knowledgePointTree', 'tags'],
  data() {
    return {
      initK: [],
      initQ: {},
      knowledgePoints: [],
      filterText: '',
      saveLoading: false,
      releaseLoading: false,
      showDrawer: false,
      analysisHover: false,
      stemHover: false,
      spinShow: false,
      treeProps: {
        expandTrigger: 'hover',
        multiple: true,
        checkStrictly: true,
        label: 'name',
        value: 'id'
      },
      addOP: false,
      editOP: '',
      opIndex: '',
      editAnalysis: false,
      editStem: false,
      delLoading: false,
      cardH: document.body.clientHeight - 235,
      questionForm: {},
      difficulty: [
        {
          value: 0,
          label: '简单'
        },
        {
          value: 1,
          label: '中等'
        },
        {
          value: 2,
          label: '困难'
        }
      ],
      questionType: [
        {
          value: 0,
          label: '判断题'
        },
        {
          value: 1,
          label: '单选题'
        },
        {
          value: 2,
          label: '多选题'
        },
        {
          value: 3,
          label: '填空题'
        },
        {
          value: 4,
          label: '简答题'
        },
        {
          value: 5,
          label: '代码题'
        }
      ],
      isChecked: false
    }
  },
  computed: {
    ...mapState({
      userId: state => state.user.info.userId
    }),
    isShow() {
      if (_.isEqual(this.questionForm, this.initQ) && _.isEqual(this.knowledgePoints, this.initK)) {
        return false
      } else {
        return true
      }
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    cancelEdit() {
      this.addOP = false
      this.editOP = ''
      this.opIndex = ''
      this.editAnalysis = false
      this.editStem = false
      this.getDetail()
    },
    showDraw() {
      this.showDrawer = true
      this.$nextTick(() => {
        this.$refs.kInput.focus()
      })
    },
    handleCreate(val) {
      this.tags.push({ name: val })
    },
    clearTag() {
      this.knowledgePoints = []
      this.$refs.modalTree.setCheckedNodes([])
    },
    closeTag(index) {
      this.knowledgePoints.splice(index, 1)
      let key = this.knowledgePoints.map(item => {
        return item.id || item.knowledgeId
      })
      this.$refs.modalTree.setCheckedKeys(key)
    },
    handleCheckChange() {
      let node = this.$refs.modalTree.getCheckedNodes()
      this.knowledgePoints = node.map(item => {
        item.knowledgeName = item.name
        item.knowledgeId = item.id
        return item
      })
    },
    release(status) {
      let type = this.questionForm.questionType
      if (type === 1 || type === 2) {
        let o = []
        if (this.questionForm.options.length < 2) {
          this.$message.warning('最少2个选项，请添加选项')
          return
        }
        this.questionForm.options.forEach(item => {
          o.push(item.correct)
        })
        if (o.indexOf(true) === -1) {
          this.$message.warning('请选择正确的选项')
          return
        }
      } else if (type === 3) {
        if (this.questionForm.blanks.length === 0) {
          this.$message.warning('请添加填空')
          return
        }
      }
      this.showDrawer = false
      let ids = []
      if (this.knowledgePoints) {
        ids = this.knowledgePoints.map(item => {
          return item.knowledgeId
        })
      }
      let tag = []
      this.questionForm.tags.forEach(item => {
        tag.push({ name: item })
      })
      let stem = this.cleanWord(this.questionForm.stem)
      const payload = {
        questionType: this.questionForm.questionType,
        knowledgePointIds: ids,
        expectedTime: this.questionForm.expectedTime,
        stem: stem,
        analysis: this.questionForm.analysis,
        questionBankId: this.$route.params.id,
        authorId: this.userId,
        difficulty: this.questionForm.difficulty,
        answer: this.questionForm.answer || null,
        options: this.questionForm.options || null,
        blanks: this.questionForm.blanks || null,
        fileUrl: null,
        // template: this.questionForm.template || null,
        codeQuestionTemplates: this.questionForm.template || null,
        status: status,
        tags: tag
      }
      if (payload.questionType === 3) {
        payload.blanks.map(item => {
          item.blankAnswer = item.blankAnswer.split(',')
          return item
        })
      }
      status === 1 ? (this.saveLoading = true) : (this.releaseLoading = true)
      questionApi
        .modifyQuestion(this.questionId, payload)
        .then(res => {
          if (status === 1) {
            this.$message.success('保存成功')
            // this.saveLoading = false
          } else {
            this.$message.success('发布成功')
            // this.releaseLoading = false
          }
          this.$parent.getMoreData()
        })
        .finally(() => {
          if (status === 1) {
            this.saveLoading = false
          } else {
            this.releaseLoading = false
          }
        })
      // .catch(() => {
      //   // if (status === 1) {
      //   //   this.saveLoading = false
      //   // } else {
      //   //   this.releaseLoading = false
      //   // }
      // })
    },
    addBlank() {
      this.questionForm.blanks.push({
        blankId: `__${this.questionForm.blanks.length + 1}__`,
        blankAnswer: ''
      })
      this.$nextTick(() => {
        this.$refs[`input${this.questionForm.blanks.length - 1}`][0].focus()
      })
    },
    confirmBlank(i) {
      if (this.questionForm.blanks[i].blankAnswer === '') {
        this.delBlank(i)
      }
    },
    delBlank(i) {
      this.questionForm.blanks.splice(i, 1)
      this.questionForm.blanks.forEach((item, index) => {
        item.blankId = `__${index + 1}__`
      })
    },
    close(i, type) {
      if (this.questionForm.options[i].content === '' || this.questionForm.options[i].content === '<p><br></p>') {
        this.$message.warning('请输入内容')
      } else {
        type === 'edit' ? (this.editOP = '') : (this.addOP = false)
      }
    },
    closeStem() {
      if (this.questionForm.stem === '' || this.questionForm.stem === '<p><br></p>') {
        this.$message.warning('请输入题干')
      } else {
        this.editStem = false
      }
    },
    setCorrect(val, i) {
      if (this.questionForm.questionType === 1) {
        this.questionForm.options.forEach(item => {
          item.correct = false
        })
        this.questionForm.options[i].correct = val
      }
    },
    showEnd(i) {
      let a
      if (this.addOP && i === this.questionForm.options.length - 1) {
        a = false
      } else {
        a = true
      }
      return a
    },
    del() {
      this.$Modal.confirm({
        title: '提示',
        content: '<p>确认删除该题目吗？</p>',
        onOk: () => {
          this.delLoading = true
          questionApi
            .deleteQuestion(this.questionId)
            .then(() => {
              this.delLoading = false
              this.$parent.getMoreData()
              this.$message.success('删除成功')
            })
            .catch(() => {
              this.delLoading = false
            })
        }
      })
    },
    getDetail() {
      if (this.questionId) {
        this.spinShow = true
        questionApi.getQuestionInfo(this.questionId).then(res => {
          this.questionForm = res.res
          this.isChecked = res.res.status === 0
          this.questionForm.tags = this.questionForm.tags.map(item => {
            return item.name
          })
          this.$refs.modalTree.setCheckedNodes([])
          if (this.questionForm.knowledgePoints) {
            let key = this.questionForm.knowledgePoints.map(item => {
              return item.id || item.knowledgeId
            })
            this.$refs.modalTree.setCheckedKeys(key)
          }
          let node = this.$refs.modalTree.getCheckedNodes()
          this.initK = node.map(item => {
            item.knowledgeName = item.name
            item.knowledgeId = item.id
            return item
          })
          this.knowledgePoints = this.initK
          if (this.questionForm.questionType === 3) {
            this.questionForm.blanks = this.questionForm.blanks.map(item => {
              item.blankAnswer = item.blankAnswer.join(',')
              return item
            })
          }
          if (this.questionForm.questionType === 0) {
            this.questionForm.answer = this.questionForm.answer.toString()
          }
          this.initQ = JSON.parse(JSON.stringify(this.questionForm))
          this.spinShow = false
          if (res.res.questionType === 5) {
            let d = {
              modifyQuestionCodeTemplate: res.res.codeQuestionTemplates
            }
            this.$refs['code-editor'].getData(d.modifyQuestionCodeTemplate)
          }
        })
      } else {
        this.questionForm = {}
      }
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    }
  },
  watch: {
    questionId(id) {
      this.questionId = id
      this.editAnalysis = false
      this.editStem = false
      this.addOP = false
      this.editOP = ''
      this.getDetail()
      this.filterText = ''
      this.showDrawer = false
    },
    filterText(val) {
      this.$refs.modalTree.filter(val)
    }
  }
}
</script>

<style scoped lang="less">
.edit {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
  opacity: 1;
  .form {
    padding: 40px 20px 0 20px;
    overflow: auto;
    .kInput {
      cursor: pointer;
      min-height: 50px;
      width: calc(100% - 230px);
      background: rgba(255, 255, 255, 1);
      border: 2px solid rgba(234, 237, 241, 1);
      opacity: 1;
      border-radius: 4px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      ::v-deep .ivu-tag {
        font-size: 12px;
        font-weight: 400;
        height: 26px;
        line-height: 26px;
        .ivu-tag-text {
          color: #415e8d !important;
        }
        .ivu-icon {
          color: #415e8d !important;
        }
      }
    }
    .option-action {
      top: 0;
      height: 42px;
      position: absolute;
      right: 20px;
      display: flex;
      align-items: center;
    }
    .hide {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
      &:hover {
        color: #6a8abe;
      }
    }
    ::v-deep .ivu-select-dropdown {
      z-index: 10009;
    }
    ::v-deep .ivu-radio-group-button .ivu-radio-wrapper-checked {
      color: white;
      border-color: #2d8cf0;
      background-color: #2d8cf0;
      box-shadow: none;
      -webkit-box-shadow: none;
    }
    ::v-deep .ivu-radio-group-button .ivu-radio-wrapper {
      &:hover {
        color: inherit;
      }
    }
    ::v-deep .ivu-radio-group-button .ivu-radio-wrapper-checked {
      &:hover {
        color: white;
      }
    }
  }
  .foot {
    height: 50px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.03);
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .cancel {
      width: 70px;
      height: 32px;
      border: 1px solid rgba(220, 222, 226, 1);
      border-radius: 4px;
      background: rgba(242, 242, 242, 1);
      color: #5e6470;
      margin-right: 20px;
      line-height: 32px;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        background: rgba(230, 230, 230, 1);
      }
    }
  }
}
::v-deep .w-e-text-container {
  word-break: break-word;
  word-break: break-all;
}
::v-deep .ivu-drawer {
  top: 186px;
  height: calc(100vh - 207px);
  .ivu-drawer-body {
    padding: 10px 4px 10px 10px;
  }
}
::v-deep .ivu-drawer-wrap {
  z-index: 10001;
}
::v-deep .el-tree {
  .el-tree-node__content {
    display: block !important;
  }
  .el-tree-node__children {
    overflow: visible !important;
  }
}
.tag ::v-deep .ivu-select-not-found {
  display: block !important;
}
</style>
