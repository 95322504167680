<template>
  <Modal v-model="showInputQuestion" title="批量导入题目" :mask-closable="false" footer-hide>
    <el-form size="small" label-width="100" ref="fileForm">
      <el-form-item label="文件:">
        <input ref="inputFile" type="file" @change="getFile" style="margin: 0 0 0 10px" />
      </el-form-item>
      <el-form-item label="提示:">
        <div>
          <p>支持扩展名：.xls .xlsx</p>
          <a :href="modelDownloadUrl">下载模板</a>
        </div>
      </el-form-item>
      <el-form-item>
        <div style="text-align: center;width: 100%;">
          <Button @click="InputCancel" style="margin: 50px 15px 0 0;width: 20%">取消</Button>
          <Button type="primary" @click="InputConfirm" style="margin-top: 50px;width: 20%" :loading="loading">确认</Button>
        </div>
      </el-form-item>
    </el-form>
    <!--    <div>-->
    <!--      <p style="float: left">文件:</p>-->
    <!--      <form ref="fileForm">-->
    <!--        <input type="file" @change="getFile" style="margin: 0 0 0 10px">-->
    <!--      </form>-->
    <!--      <div style="margin: 15px 0 0 35px">-->
    <!--        <p>支持扩展名：.xls .xlsx</p>-->
    <!--        <a :href="modelDownloadUrl">下载模板</a>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div style="text-align: center">-->
    <!--      <Button @click="InputCancel" style="margin: 50px 15px 0 0;width: 20%">取消</Button>-->
    <!--      <Button type="primary" @click="InputConfirm" style="margin-top: 50px;width: 20%" :loading="loading">确认</Button>-->
    <!--    </div>-->
  </Modal>
</template>

<script>
import questionApi from '../../../../../api/questionRepo'
export default {
  props: {
    questionBankIds: {
      tyep: String
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    modelDownloadUrl() {
      return questionApi.downloadModelUrl
    }
  },
  data() {
    return {
      showInputQuestion: this.value,
      loading: false,
      file: null
    }
  },
  methods: {
    getFile(event) {
      this.file = event.target.files[0]
    },
    message(msg, type) {
      this.$message({ message: msg, type: type })
    },
    InputConfirm(event) {
      if (this.$refs.inputFile.value === null || this.file === null) return this.message('请先上传文件', 'warning')
      this.loading = true
      event.preventDefault()
      let formData = new FormData()
      formData.append('file', this.file)
      questionApi
        .uploadQuestion(this.questionBankIds, formData)
        .then(res => {
          if (res.code === 0) {
            if (res.res.successCount !== 0) {
              this.$message.success(`成功导入${res.res.successCount}道题目!`)
              this.$parent.getAllQuestion(0, 10)
              this.InputCancel()
            } else {
              this.$message.warning('导入失败')
              // this.$message.warning('导入失败' + '原因' + res.res.failReport)
            }
          } else {
          }
          this.$refs.inputFile.value = null
          this.file = null
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    InputCancel() {
      this.file = null
      this.$refs.inputFile.value = ''
      this.showInputQuestion = false
      this.$emit('changeShowInput', false)
      this.$emit('on-cancel')
    }
  },
  watch: {
    value(val) {
      this.showInputQuestion = val
    }
  }
}
</script>

<style scoped></style>
