<template>
  <div class="input-wrapper-layout" :style="dynamicWrapperStyle">
    <div class="label" :style="dynamicLabelStyle">{{ label }}</div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'InputWrapper',
  props: {
    label: {
      type: String
    },
    labelWidth: {
      type: Number
    },
    align: {
      type: String,
      default: 'center'
    }
  },
  computed: {
    dynamicLabelStyle() {
      return this.labelWidth ? `width: ${this.labelWidth}px` : ''
    },
    dynamicWrapperStyle() {
      const possibleValue = ['top', 'center', 'bottom']
      const flexPosition = ['flex-start', 'center', 'flex-end']
      const idx = possibleValue.indexOf(this.align)
      const align = idx === -1 ? 'center' : flexPosition[idx]
      return `align-items: ${align}`
    }
  }
}
</script>

<style lang="less" scoped>
.input-wrapper-layout {
  display: flex;
  /*align-items: center;*/
  margin-top: 10px;
  .label {
    white-space: nowrap;
    margin-right: 10px;
  }
}
</style>
